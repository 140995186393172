import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";

import "./CheckOutPayment.css";

import {
  getDataForCheckoutPayment,
  resetDataOnEnter,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefResumen,
  setRefPayment,
  changeCbuText,
  actionCheckAdminSucursal
} from "../../../actions";

import { CHECKOUT_DATA, LOADING } from "../../../actions/types";

import { useMediaQuery } from "react-responsive";
import PaymentCardComponent from "../PaymentCardComponent/PaymentCardComponent";
import CardSelectorCheckOutComponent from "../CardSelectorCheckOutComponent/CardSelectorCheckOutComponent";
import DiscountDetailsComponent from "../DiscountDetailsComponent/DiscountDetailsComponent";
import SuccessCard from "../../generalComponents/SuccessCard/SuccessCard";
import { PaymentMethods } from "../../../services/PaymentMethods";
import { savePaymentMethod } from "../../../actions/CheckOutActions";
import { PaymentMethodComponentRef } from "../../../paymentMethod/PaymentMethodComponent";
import { genericErrorHandler } from "../../../util/GenericErrorHandler";
import PaymentMethodCreditCard from "../PaymentMethodCreditCard/PaymentMethodCreditCard";

let isTabletOrMobile = false;
const CheckOutPayment = (props) => {
  isTabletOrMobile = useMediaQuery({ query: "(max-width: 37.5em)" });

  const dispatch = useDispatch();
  const [showCardAdd, setShowCardAdd] = useState(-1);
  const [cardType, setCardType] = useState<string>();
  const [cardSelected, setCardSelected] = useState<any>(undefined);
  const [cuotas, setCuotas] = useState<any>(null);
  const [isAdminSucursal, setIsAdminSucursal] = useState<boolean>(false);
  const [refererErrorEmpty, setRefererError] = useState<boolean>(false);
  const [existingPaymentSelected, setExistingPaymentSelected] = useState<any>(null);
  const [appId] = useState<any>(process.env.REACT_APP_ID);

  /** El medio de pago que se esta configurando, ya esta listo para continuar? */
  const [paymentConfigStatus, setPaymentConfigStatus] = useState<boolean>(false);

  const paymentMethodComponentRef: any = useRef<PaymentMethodComponentRef>();

  const refPayment = useRef<HTMLDivElement>(null);

  useEffect(() => {
    props.actionCheckAdminSucursal(setIsAdminSucursal)
  }, [])

  useEffect(() => {
    if(isAdminSucursal && !props.refererApplied && appId == 7) setRefererError(true)
  }, [isAdminSucursal, props.refererApplied])

  useEffect(() => {
    if(props.refererApplied) setRefererError(false)
  }, [props.refererApplied])

  useEffect(() => {
    props.getDataForCheckoutPayment(props.checkOutData, setExistingPaymentSelected)
  }, []);

  useEffect(() => {
    if (props.checkOutPaymentOptions?.length === 1) {
      setShowCardAdd(props.checkOutPaymentOptions[0]?.id); 
      selectCreditCard(undefined);
      changePaymentMethod(props.checkOutPaymentOptions[0]?.type);
    }
  }, [props.checkOutPaymentOptions])

  useEffect(() => {
    if (props.paymentRef) {
      scrollToPayment();
      props.setRefPayment(false);
    }
  }, [props.paymentRef]);

  const scrollToPayment = () => {
    refPayment.current?.scrollIntoView({ behavior: "smooth" });
  };

  const changePaymentMethod = (name) => {
    setPaymentConfigStatus(false);
    setCardType(name);
  }

  const selectCreditCard = (creditCard) => {
    changePaymentMethod(undefined);
    setCardSelected(creditCard);
    setPaymentConfigStatus(creditCard !== null);
  }

  const paymentMethods = PaymentMethods;

  /**
   * Metodo que se ejecuta cuando el usuario le da a continuar.
   */
  const handleFinish = async () => {


    dispatch({ type: LOADING, payload: true });

    let paymentId: number;
    let paymentType: string;

    if (paymentMethodComponentRef.current == null) {
      paymentId = cardSelected.id;
      paymentType = 'creditcard';
    } else {

      try {
        if (cardType === undefined) {
          dispatch({ type: LOADING, payload: true });
          return;
        }
        // Le damos a continuar al subcomponente, que nos tiene que dar un paymentdhata
        const paymentData = await paymentMethodComponentRef.current.continue();
        paymentId = paymentData.id;
        paymentType = cardType;
      } catch (e) {
        dispatch({ type: LOADING, payload: false });
        return;
      }
    }

    try {
        // Establecemos en el checkout el nuevo metodo de pago seleccionado.
        let data = {}
        if(cardType === 'pat_chile'){
          data = {
            paymentType: paymentType,
            id: paymentId,
            installments: cuotas?.installment
          }
        } else {
          data = {
            paymentType: paymentType,
            id: paymentId
          }
        }
        const checkoutNew = await savePaymentMethod(props.checkOutData?.id, data);

        // Actualizamos el front con el nuevo checkout.
        dispatch({ type: CHECKOUT_DATA, payload: checkoutNew });

        await props.setRefResumen(true);
    } catch(error) {
      genericErrorHandler(error);
      console.error(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  };

  /**
   * Metodo que se llama cuando el componente de carga del pago cambia de esado
   * @param  status  True si esta listo para continuar
   */
  const onChangePaymentStatus = (status) => {
    setPaymentConfigStatus(status);
  };

  /**
   * Renderiza la configuración del medio de pago elegodo.
   * @returns
   */
  const renderSelectedCardComponent = () => {
    if (cardType == null) return;
    const PaymentConfigComponent = paymentMethods[cardType].configComponent;
    return (
      <PaymentConfigComponent
        ref={paymentMethodComponentRef}
        userId={props.checkOutData?.policyHolder?.id}
        id={cardType}
        key={cardType}
        onChangeStatus={onChangePaymentStatus}
        setCuotas={setCuotas}
        cuotas={cuotas}
        changeCbuText={(e: any) => props.changeCbuText(e)}
      />
    );
  };

  const getPaymentType = () => {
    let paymentId: number;
    let paymentType: string | undefined;
    if (paymentMethodComponentRef.current == null) {
      paymentId = cardSelected?.id;
      paymentType = cardType;
    } else {
      const paymentData = paymentMethodComponentRef?.current?.continue();
      paymentId = paymentData?.id;
      paymentType = cardType;
    }
    let data = {}
    if(cardType === 'pat_chile'){
      data = {
        paymentType: paymentType,
        id: paymentId,
        installments: cuotas?.installment
      }
    } else {
      data = {
        paymentType: paymentType,
        id: paymentId
      }
    }
    return data;
  }

  const renderFinalPaymentButton = () => {
    return <PaymentMethodCreditCard paymentType={getPaymentType()} disabled={!paymentConfigStatus} refererErrorEmpty={refererErrorEmpty} originalParams={props.originalParams} admin={props.admin} isAdminSucursal={isAdminSucursal} setRefererError={setRefererError}/>
  }

  const renderMainComponent = () => {
    if (props.userCreditCards) {
      return (
        <div className="checkOutPayment-container-main-optionsCards">
          <div className="checkOutPayment-container-main-optionsCards-options">
            <p className="checkOutPayment-container-main-title">Elegí tu medio de pago</p>
            <p className="checkOutPayment-container-main-subTitle">Tu compra es 100% segura</p>
            {props.checkOutPaymentOptions?.map((a, index) => (
                <CardSelectorCheckOutComponent
                  index={index}
                  isLast={props.checkOutPaymentOptions?.length === index + 1}
                  key={a.id}
                  selected={showCardAdd === a.id}
                  name={a.name}
                  icon={a.icon}
                  image={a.image}
                  options
                  cardSelected={() => {
                    if (showCardAdd === a.id) {
                      setShowCardAdd(-1);
                      changePaymentMethod(undefined);
                    } else {
                      setShowCardAdd(a.id); 
                      setTimeout(() => {
                        changePaymentMethod(a.type);
                      }, 2250)
                    }
                  }}
                  disabled={a.comingSoon}
                />
              ))}
          </div>
          {props.userCreditCards?.length !== 0 && <div className="checkOutPayment-container-main-optionsCards-cards">
            <p className="checkOutPayment-container-main-title">Medios de pago guardados</p>
            {!isTabletOrMobile && <p className="checkOutPayment-container-main-subTitle">&nbsp;</p>}
            {props.userCreditCards?.map((a, index) => (
                <CardSelectorCheckOutComponent
                  index={index}
                  isLast={props.userCreditCards?.length === index + 1}
                  key={a.id}
                  selected={
                     cardSelected !== undefined
                      ? cardSelected.id === a.id
                      : false
                  }
                  name={"**** **** **** " + a.lastFour}
                  icon={a.brandCardId}
                  cardSelected={() => {
                    if (cardSelected === a) { 
                      selectCreditCard(undefined)
                    } else {
                      setShowCardAdd(-1);
                      selectCreditCard(a);
                    }
                  }}
                />
              ))}
          </div>}
        </div>
      );
    } else {
      return (
        <div className="checkOutPayment-container-main-options">
          {props.checkOutPaymentOptions?.map((a) => (
              <PaymentCardComponent
                key={a.id}
                data={a}
                paymentSelected={() => {}}
              />
            ))}
        </div>
      );
    }
  };

  return (
    <div className="checkOutPayment-container" ref={refPayment}>
      <SuccessCard translateX={0} translateY={isTabletOrMobile ? -1000 : 0} />
      <div className="checkOutPayment-container-main">
        <div 
          className="checkOutPayment-container-main-volver"
          onClick={() => props.goBack()}
        >
          <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
          <b className="checkOutPayment-container-main-volver-text bold medium">Volver</b>
        </div>
        {renderMainComponent()}
        {cardType && renderSelectedCardComponent()}
      </div> 
      <div className="checkOutProfile-container-endWrapper">
        <div className="checkOutProfile-container-endWrapper-main">
          <DiscountDetailsComponent refererErrorEmpty={refererErrorEmpty} isAdminSucursal={isAdminSucursal}/>
          {renderFinalPaymentButton()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
    checkOutPaymentOptions: state.checkOut.checkOutPaymentOptions,
    userCreditCards: state.checkOut.userCreditCards,
    paymentRef: state.general.paymentRef,
    refererApplied: state.checkOut.refererApplied
  };
};

export default connect(mapStateToProps, {
  getDataForCheckoutPayment,
  resetDataOnEnter,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefResumen,
  setRefPayment,
  changeCbuText,
  actionCheckAdminSucursal,
})(CheckOutPayment);
